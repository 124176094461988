import React from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import "./paypalStyles.css";

const PaypalPage = () => {
  const [{ options, isPending }] = usePayPalScriptReducer();
  const [selectedPackage, setSelectedPackage] = React.useState("20.00");
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    vinNumber: "",
    package: selectedPackage,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  const handlePackageSelect = (amount) => {
    setSelectedPackage(amount);
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      units: [
        {
          amount: {
            value: selectedPackage,
            currency_code: "USD",
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      // Handle successful payment
      console.log(details, data);
      // Perform actions after successful payment (e.g., order confirmation, email notification)
    });
  };

  const onError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <div>
        <div>
          <img src="/images/logo.png" alt="logo" />
        </div>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <input
              type="text"
              value={formData.name}
              name="name"
              onChange={handleChange}
              placeholder="Enter your name"
              style={{ width: "100%", height: "40px" }}
            />
            <input
              value={formData.email}
              onChange={handleChange}
              name="email"
              type="email"
              placeholder="Enter your email"
              style={{ width: "100%", height: "40px" }}
            />
            <input
              value={formData.phoneNumber}
              onChange={handleChange}
              name="phoneNumber"
              type="text"
              placeholder="Enter your phone number"
              style={{ width: "100%", height: "40px" }}
            />
            <input
              value={formData.address}
              onChange={handleChange}
              name="address"
              type="text"
              placeholder="Enter your address"
              style={{ width: "100%", height: "40px" }}
            />
            <input
              value={formData.vinNumber}
              onChange={handleChange}
              name="vinNumber"
              type="text"
              placeholder="Enter your vin number"
              style={{ width: "100%", height: "40px" }}
            />
            <select>
              <option>Select your package</option>
              <option
                id="package1"
                name="package"
                value="35.00"
                onChange={() => handlePackageSelect(35.0)}
              >
                $35
              </option>
              <option
                id="package2"
                name="package"
                value="45.00"
                onChange={() => handlePackageSelect(45.0)}
              >
                $45
              </option>
              <option
                id="package3"
                name="package"
                value="52"
                onChange={() => handlePackageSelect(52.0)}
              >
                $52
              </option>
              <option
                id="package3"
                name="package"
                value="71.00"
                onChange={() => handlePackageSelect(71.0)}
              >
                $71
              </option>
              <option
                id="package3"
                name="package"
                value="109.00"
                onChange={() => handlePackageSelect(109.0)}
              >
                $109 (Platinum)
              </option>
            </select>
            {/* <button className="cs_btn cs_style_1" type="submit">
                Submit
              </button> */}
            {isPending ? (
              <p>Loading...!!</p>
            ) : (
              <PayPalScriptProvider
                options={{
                  "client-id":
                    "AaEjQgd80pF5_JBr6by0OcQ9yh8qmmcC4vEc0desTuE9WvWnG4ohJGxML6q0K2RMMAjDd3ECZkrhK0Ka",
                  //   deferLoading: true,
                  currency: "USD",
                }}
              >
                <PayPalButtons
                  createOrder={(data, actions) => createOrder(data, actions)}
                  onApprove={(data, actions) => onApprove(data, actions)}
                  onError={onError}
                />
              </PayPalScriptProvider>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaypalPage;
