import React from "react";
import { pageTitle } from "../../helpers/PageTitle";
import "./styles.css";

const DataProduct = () => {
  pageTitle("Data Product");
  return (
    <div className="main-data-product">
      <div className="first-section">
        <div>
          <h1 className="first-heading">AUTOMOTIVE MARKET DATA FEEDS</h1>
          <p className="first-text">
            Our platform is continually synced with the Canadian and US
            automotive markets, systematically updating a database of over 15
            million listings. Leverage a repository of more than 100 million
            data points obtained from 70,000 dealerships across North America.
            Access this rich pool of information effortlessly via our robust set
            of APIs, tailored to offer seamless access to a diverse range of
            data sources. Tapping into our vast database ensures that your
            business and customers can get comprehensive insights and make
            informed decisions.
          </p>
        </div>
        <div>
          <img src="/images/corolla.png" alt="" className="first-img" />
        </div>
      </div>
      <div className="second-section-bs">
        <h2>VEHICLE DATA APIS</h2>
        <p>
          Streamline the integration of vehicle data into your systems with our
          APIs, tailored for Canadian auto industry businesses. Effortlessly
          integrate “brandless data” such as vehicle specifications, values,
          history, and other essential information. With over a decade of
          experience, we’ve curated 30 billion exclusive records from a wide
          range of sources. Our commitment to ensuring that you have access to
          the market’s cleanest and most dependable vehicle datasets.
        </p>
      </div>
      <div className="third-section-v">
        <div className="third-img-div">
          <img src="/images/veh.png" alt="" className="second-img" />
        </div>
        <div>
          <h2 className="second-heading-a">VEHICLE HISTORY API</h2>
          <p className="second-text-a">
            Gain access to an expansive database of unprocessed vehicle data
            sourced from the VinAudit vehicle history report, encompassing
            detailed information from both the United States and Canada. With
            bulk access, you can tap into a wealth of data, providing you with
            in-depth and comprehensive insights. This empowers you to make
            informed decisions with confidence, whether you’re in the automotive
            industry or need to access reliable vehicle information for various
            purposes.
          </p>
        </div>
      </div>
      <div className="third-section-v">
        <div>
          <h2 className="second-heading-a">VEHICLE SPECIFICATION API</h2>
          <p className="second-text-a">
            Explore our extensive database of vehicle specifications by VIN,
            thoughtfully collected from our industry partners across Canada.
            Effortlessly incorporate VinAudit data into your customized
            applications by utilizing your personal API key. This integration
            empowers you to access precise and up-to-date specs wherever it is
            needed in the Canadian market.
          </p>
        </div>
        <div className="third-img-div">
          <img src="/images/ve.png" alt="" className="second-img" />
        </div>
      </div>
      <div className="third-section-v">
        <div className="third-img-div">
          <img src="/images/au.png" alt="" className="second-img" />
        </div>
        <div>
          <h2 className="second-heading-a">VEHICLE IMAGES API</h2>
          <p className="second-text-a">
            Seamlessly incorporate authentic, high-quality vehicle images for
            any year, make, model, or trim into your product or website. This
            API is designed to enhance the visual experience of your users.
            Whether you’re managing an automotive website, creating a product,
            or engaging in marketing activities, we offer a wide range of images
            that contribute to a more engaging and informative experience for
            your Canadian audience.
          </p>
        </div>
      </div>
      <div className="third-section-v">
        <div>
          <h2 className="second-heading-a">VEHICLE BACKGROUND REMOVAL</h2>
          <p className="second-text-a">
            Elevate the visual appeal and professionalism of vehicle images with
            our advanced Vehicle Background Removal tool. This tool seamlessly
            removes backgrounds from vehicle photos, resulting in clean and
            eye-catching visuals. Whether you’re a dealership, automotive
            website, or marketing agency in Canada, this feature enhances the
            presentation of vehicles.
          </p>
        </div>
        <div className="third-img-div">
          <img src="/images/te.png" alt="" className="second-img" />
        </div>
      </div>
    </div>
  );
};

export default DataProduct;
