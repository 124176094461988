import React from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import CtaStyle2 from "../Cta/CtaStyle2";
import AboutStyle6 from "../About/AboutStyle6";
import { pageTitle } from "../../helpers/PageTitle";

export default function ServiceDetailsPage() {
  pageTitle("Vin Check");
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="What is a VIN Check and Why is it Important?"
        subTitle=""
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage: 'url("/images/vin.jpg")',
                }}
              />
            </div>
            <div className="col-lg-3">
              <p>
                A VIN check is a process that involves using a vehicle’s unique
                Vehicle Identification Number (VIN) as a key to accessing and
                retrieving various types of data about the vehicle. The
                17-character alphanumeric code is assigned to every motor
                vehicle, including cars, trucks, motorcycles, and recreational
                vehicles. It is assigned to each vehicle by a car manufacturer
                upon production so that no two cars can have the same VIN. Using
                the VIN number, individuals and businesses can access a wide
                range of vehicle data that are useful in making informed
                decisions when buying or selling a used car, or maintaining a
                vehicle. There are different ways the term is used to refer to
                checking vehicle data.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="75" md="60" />
      <div
        style={{
          textAlign: "center",
          maxWidth: "1140px",
          width: "100%",
          margin: "0 auto",
        }}
      >
        <h1>Different Versions of VIN Check</h1>
        <p>
          Basic VIN Check refers to a simple lookup of the VIN to retrieve basic
          information about the vehicle, such as the make, model, year of
          production, engine type, and manufacturing plant. It is a quick way to
          confirm the basic details of a used car without going into its
          complete history.Stolen Vehicle VIN Search refers to searching the VIN
          against databases of stolen vehicles. This ensures that a buyer is not
          unintentionally buying a stolen car.Salvage VIN Number Lookup is used
          to determine if a vehicle has been branded as salvage or has been
          damaged to the extent that the cost of repairs exceeds its market
          value. This is useful for assessing a vehicle’s condition and value
          before making a purchase.Recall Check by VIN Number involves checking
          for any outstanding recalls or safety issues associated with the
          vehicle. This helps identify potential safety concerns and the need
          for necessary repairs or modifications.Warranty Check involves looking
          up a VIN to find out if a vehicle is covered by a warranty. In most
          cases, this process only applies to factory warranties only. Verifying
          the status of extended warranty coverage may require contacting the
          provider to get information about expiration.VIN Decoder, while not
          explicitly called a VIN check, is a tool or service that takes a VIN
          as input and decodes it to provide detailed information about the
          vehicle’s manufacturing characteristics. It can reveal specific
          details such as the country of origin, body style, engine size, and
          more.Free VIN Check is a free vehicle history service that offers
          limited or preliminary information. In some cases, it is used as a
          marketing tool to entice a consumer who wants to buy a used car to
          upgrade to a paid vehicle history report.Vehicle History Report is
          generated through an extensive check that involves tracing the
          vehicle’s past, from its production to its current condition under the
          most recent owner. A car report includes information on accidents,
          title status, ownership history, service records, recalls, and more.
        </p>
      </div>
      <Spacing lg="100" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/vi.jpg"
        title="Different Versions of VIN Check"
        subTitle="Your vehicle’s unique Vehicle Identification Number (VIN) is a 17-digit number that can be found on the title document, the vehicle registration, and on the insurance policy. The VIN can also be located at the following locations on the car itself:"
        featureList={[
          "On the driver’s side dashboard",
          "On the driver’s side door",
        ]}
      />
      <Spacing lg="100" md="80" />
      <div
        style={{
          textAlign: "center",
          maxWidth: "1140px",
          width: "100%",
          margin: "0 auto",
        }}
      >
        <h1>The Difference VinAudit Vehicle History Reports Make</h1>
        <p>
          Vehicle Vin Checkup has made a significant impact in the vehicle
          industry by offering detailed vehicle history information that goes
          beyond what most VIN check services provide. Here are the key
          differences that set vehicle Vin Checkup apart from standard VIN-based
          services and car report providers:
        </p>
        <p>
          <strong>Comprehensive Vehicle History Reports:</strong> While basic
          VIN checks often provide limited information, vehicle Vin Checkup
          offers full vehicle history reports. These reports delve into various
          aspects of a vehicle’s background, including title information,
          accident records, odometer readings, salvage and insurance history,
          recall information, and more.
        </p>
        <p>
          <strong>Reliable Data Sources:</strong> Vehicle Vin Checkup sources
          its data from multiple reliable and authoritative sources, ensuring
          the accuracy and validity of the information provided. By aggregating
          data from government agencies, auto auctions, insurance companies, and
          other trusted sources, vehicle Vin Checkup provides a full picture of
          a vehicle’s past.
        </p>
        <p>
          <strong>Additional Details and Insights:</strong> Vehicle Vin Checkup
          goes beyond basic VIN checks by providing additional details and
          insights that are valuable to buyers, sellers, and industry
          professionals. These details may include vehicle specifications,
          historical sales data, market values, and more.
        </p>
        <p>
          <strong>Enhanced Risk Assessment:</strong> Vehicle Vin Checkup auto
          reports enable a more thorough risk assessment compared to standard
          VIN checks. We help users identify potential issues, such as title
          discrepancies, previous accidents, or flood damage, which may impact a
          vehicle’s value, safety, or legal status.
        </p>
      </div>
      <Spacing lg="100" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/vinP.png"
        title="Why should I run a VIN check?"
        subTitle="Outside of our homes, a car is often one of the most expensive assets we purchase. As a vehicle buyer, you can reduce your risk of hidden problems and intentional fraud. As a vehicle seller, you can help increase confidence for potential buyers by proving that your car has a clean history — and being upfront about defects if any are found. Most people run a VIN check to:"
        featureList={[
          "Understand a vehicle’s past ownership history",
          "Check for hidden issues or prior damage",
          "Avoid getting intentionally scammed or defrauded",
          "Make an informed assessment of a vehicle’s value",
        ]}
      />
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Industry giants trust Audit Your Vin. So should you."
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
