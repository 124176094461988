/* eslint-disable import/first */
import React from "react";
import Spacing from "../Spacing";
import SectionHeading from "../SectionHeading";
import VideoModal from "../VideoModal";
import IconBoxStyle5 from "../IconBox/IconBoxStyle5";
import BrandCarousel from "../Slider/BrandCarousel";
import { pageTitle } from "../../helpers/PageTitle";

const brandData = [
  { logoSrc: "/images/logo1.png", alt: "Brand" },
  { logoSrc: "/images/logo2.png", alt: "Brand" },
  { logoSrc: "/images/logo3.jpeg", alt: "Brand" },
  { logoSrc: "/images/logo4.png", alt: "Brand" },
  { logoSrc: "/images/logo5.jpeg", alt: "Brand" },
];

export default function TechStartupPage() {
  pageTitle("Dealer Program");
  return (
    <>
      <div className="">
        <Spacing lg="180" md="60" />
        <div className="container">
          <BrandCarousel data={brandData} />
        </div>
        <Spacing lg="65" md="60" />
      </div>
      <section>
        <Spacing lg="60" md="75" />
        <div className="container">
          <SectionHeading
            title="Considering vehicle history options for your dealership? The good news is that there’s a new player in town. Now you can subscribe to VinAudit’s vehicle history reports from official Canada government sources, with enhanced coverage of critical issues, without the big-name cost. It’s about time!"
            subTitle="A FRESH TAKE ON VEHICLE HISTORY"
            variant="cs_type_1"
          />
          <Spacing lg="85" md="45" />
          <div className="row g-3">
            <div className="col-lg-6 col-xl-3">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/service_icon_1.svg"
                title="Official Government Data Sources"
                href="/contact"
                subTitle="Offer reports sourced from provincial departments of transportation and other official agencies from 12 provinces and territories (excluding NT)."
              />
            </div>
            <div className="col-lg-6 col-xl-3">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/service_icon_2.svg"
                title="Save Big on Your Report Subscription"
                href="/contact"
                subTitle="We pride ourselves by offering transparent pricing structures and simple agreements for dealerships. Enjoy the feeling, for once, of not getting extorted on price."
              />
            </div>
            <div className="col-lg-6 col-xl-3">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/service_icon_3.svg"
                title="Exclusive Independent Coverage"
                subTitle="Catch critical issues that other sources may miss with our independent data coverage."
                href="/contact"
              />
            </div>
            <div className="col-lg-6 col-xl-3">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/service_icon_4.svg"
                title="24/7 Online and Mobile Access"
                subTitle="Get instant access to reports through our web and mobile interfaces."
                href="/contact"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
          bgUrl="/images/dealer-p.jpg"
          title="Ready to see the history of your vehicle?"
          titleVariant="cs_outline_text"
        />
      </div>
      <Spacing lg="80" md="70" />
    </>
  );
}
